import React from 'react';

// components
import { Container } from 'react-bootstrap';
import { Section } from '../atoms';
import { Heading, School } from '../molecules';

const Education = ({ icon, title, schools }) => (
  <Section
    modifiers={['border-top', 'padding-top', 'padding-bottom']}
    id="education"
  >
    <Container>
      <Heading icon={icon} title={title} />
      {schools.map((school, index) => (
        <School key={`school-${index}`} {...school} />
      ))}
    </Container>
  </Section>
);

export default Education;
