/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState, createContext } from 'react';
import AOS from 'aos';
import { initNavBar, destroyNavBar } from '../lib/navbar';

import copy from '../../data/copy';
import { Navigation, Footer } from '../molecules';
import { Main } from '../atoms';
import { betZero } from '../lib/utils';

export const AppContext = createContext();

// ensures the animate on scroll animations fire at the correct time
const refreshAOS = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('load', function() {
      AOS.refresh();
    });
  }
};

const Layout = ({ children }) => {
  /**
   * State changer thingy
   */
  const [visible, toggleNav] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const toggleNavigationVisibility = arg => {
    if (arg === true) {
      toggleNav(true);
    } else {
      toggleNav(false);
    }
  };

  /**
   * Constructor
   */
  useEffect(() => {
    AOS.init({
      mobile: false,
      delay: 100,
    });

    refreshAOS();

    initNavBar(toggleNavigationVisibility);

    betZero();

    return () => {
      destroyNavBar();
    };
  }, []);

  return (
    <AppContext.Provider value={{ modalShow, setModalShow }}>
      <>
        <Navigation visible={visible} {...copy.navigation} />
        <Main>{children}</Main>
        <Footer />
      </>
    </AppContext.Provider>
  );
};

export default Layout;
