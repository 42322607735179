import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { applyModifiers, makeAnimation } from '../lib/utils';

const animator = animationName => css`
  ${animationName} 1.25s infinite;
`;

const modifiers = {
  blink: `
    margin: 0 0 0 4px;
  `,
};

const animations = {
  blink: keyframes`
    to {
      opacity: 0;
    }
  `,
  uppercase: `
    text-transform: uppercase;
  `,
  'fs-22': `
    font-size: 22px;
  `,
  captcha: `
    cursor: pointer;
    text-decoration: underline;

    @media (min-width: 768px) {
      cursor: pointer;
    }
  `,
};

const StyledSpan = styled.span`
  /* base styles can go here */
  font-size: 18px;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}

  /* if animation is applied to this element */
  animation: ${props =>
    makeAnimation.bind(null, animations, animator, props.modifiers || props)};  

  @media (min-width: 768px) {
    font-size: 22px;
  }
`;

const Span = ({ children, ...props }) => (
  <StyledSpan {...props}>{children}</StyledSpan>
);

export default Span;
