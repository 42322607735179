import React from 'react';

// components
import { Col } from 'react-bootstrap';
import { P, Dl, Dt, Strong, Em, Dd, Row } from '../atoms';

const Job = ({ startYear, endYear, companyName, title, duties }) => (
  <Row spacer data-aos="fade-up">
    <Col
      sm={{ span: 3, offset: 1 }}
      lg={{ span: 3, offset: 1 }}
      className="no-padding-left"
    >
      <P>
        {startYear} - {endYear}
      </P>
    </Col>
    <Col sm={8} lg={8} xs={12}>
      <Dl>
        <Dt modifiers="margin-bottom">
          <Strong>{companyName} | </Strong>
          <Em>{title}</Em>
        </Dt>
        {duties.map((duty, index) => (
          <Dd key={`duty-${index}`}>{duty}</Dd>
        ))}
      </Dl>
    </Col>
  </Row>
);

export default Job;
