import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {};

const StyledA = styled.a`
  /* base styles can go here */
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }

  &:active {
    color: #ffffff;
  }

  &:visited {
    color: #ffffff;
  }

  &:link {
    color: #ffffff;
  }

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const A = ({ children, ...props }) => <StyledA {...props}>{children}</StyledA>;

export default A;
