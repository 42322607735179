import React from 'react';

// components
import { Container } from 'react-bootstrap';
import { Job, Heading } from '../molecules';
import { Section } from '../atoms';

const WorkExperience = ({ title, icon, jobs }) => (
  <Section modifiers="work-experience" id="work-experience">
    <Container>
      <Heading icon={icon} title={title} />
      {jobs.map((job, index) => (
        <Job key={`job-${index}`} {...job} />
      ))}
    </Container>
  </Section>
);

export default WorkExperience;
