import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  'margin-bottom': `
    margin: 0 0 15px 0;
  `,
  'normal-font-weight': `
    font-weight: 400;
  `,
};

const StyledDt = styled.dt`
  /* base styles can go here */
  font-family: 'Lato', sans-serif;
  font-size: 16px;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Dt = ({ children, ...props }) => (
  <StyledDt {...props}>{children}</StyledDt>
);

export default Dt;
