import _throttle from 'lodash/throttle';

/*
  @name toggleNavigationVisibility 
  @desc Fixes the primary navigation in place and adds animation effect to primary navigation.

  @param {boolean} arg - Fixes nav bar when true.
*/
const toggleNavigationVisibility = arg => {
  const navbar = document.getElementById('navbar');
  if (arg === true) {
    navbar.className = 'nerve navbar-fixed-top';
  } else {
    navbar.className = 'nerve';
  }
};

const _currentYPosition = function() {
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  }

  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) {
    return document.body.scrollTop;
  }

  return 0;
};

// Toggle navigation visibility if the y offset is equal to the window height - 70px
const _toggleNavigationVisibilityOnScroll = cb => {
  const scrollDistance = window.innerHeight;
  const scrollY = _currentYPosition();
  cb(scrollY > scrollDistance - 70);
};

const _bindEvent = function(event, method, flow) {
  if (window.addEventListener) {
    window.addEventListener(event, method, flow);
  } else {
    window.attachEvent(`on${event}`, method);
  }
};

const initNavBar = cb => {
  // we check for the window object because of gatsby server side rendering
  if (typeof window !== 'undefined') {
    _bindEvent(
      'scroll',
      _throttle(() => _toggleNavigationVisibilityOnScroll(cb), 100),
      false
    );
  }
};

const destroyNavBar = () => {
  if (typeof window !== 'undefined') {
    window.removeEventListener(
      'scroll',
      _throttle(() => _toggleNavigationVisibilityOnScroll(cb), 100)
    );
  }
};

export { toggleNavigationVisibility, initNavBar, destroyNavBar };
