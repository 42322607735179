// TODO: refactor this..

/*
    @name removeClasses 
    @desc Removes all classes from desired element defined in options object.

    @param {object} options - An object of options to pass to function. elem
    @param {int} timeout - Time to wait until class is removed from element.  Helpful for animations.
*/
const removeClasses = function(options, duration) {
  const timeout = duration === 'undefined' ? 0 : duration;
  setTimeout(function() {
    document.getElementById(options.elem).className = '';
  }, timeout);
};

/*
    @name addAnimation 
    @desc Adds animation class to desired element defined in options object.

    @param {object} options - An object of options to pass to function: elem className & animation class
*/
const addAnimation = function(options) {
  if (options.animation) {
    document.getElementById(
      options.elem
    ).className = `animated ${options.animation}`;
    removeClasses(options, options.animationTimeout);
  }
};

const requestAnimFrame = (function() {
  if (typeof window !== `undefined`) {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function(callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  }

  return callback => callback;
})();

/*
    @name elmYPosition 
    @desc Get the Y position of the desired element.

    @param {string} eID - Unique identifier for element
    @param {options} object - Object of options

    @returns {int} Current Y position of desired element.
*/
const elmYPosition = function(eID, options) {
  if (typeof options !== 'object') {
    options = undefined;
  }

  // vars
  const elm = document.getElementById(eID);
  let y = elm.offsetTop - (options !== undefined ? options.padding : 65);
  let node = elm;

  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }

  return y;
};

/*
    @name smoothScrollTo 
    @desc Scrolls to desired element.

    @param {string} eID - Unique identifier for the element scrolled to.
    @param {int} duration - Duration of scroll effect.
    @param {object} options - An object of options to pass to callback.
    @param {function} callback - A callback function to invoke when method completes.
*/
const smoothScrollTo = (eID, duration, options, callback) => {
  if (typeof options === 'undefined') {
    options = {};
  }

  // because it's so fucking difficult to detect the scrolling element, just move them all
  function move(amount) {
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  }

  // return current Y position
  function position() {
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  }

  // vars
  const start = position();
  const to = elmYPosition(eID, options);
  const change = to - start;
  let currentTime = 0;
  const increment = 20;
  duration = typeof duration === 'undefined' ? 500 : duration;

  // define animateScroll function
  const animateScroll = function() {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      // invokes another animation when this completes
      addAnimation(options);
    }
  };

  // invoke animateScroll function
  animateScroll();
};

// easing functions http://goo.gl/5HLl8
Math.easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

Math.easeInCubic = function(t, b, c, d) {
  const tc = (t /= d) * t * t;
  return b + c * tc;
};

Math.inOutQuintic = function(t, b, c, d) {
  const ts = (t /= d) * t;
  const tc = ts * t;
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
};

export default smoothScrollTo;
