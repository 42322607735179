import _intersection from 'lodash/intersection';

const _toCSS = (variants, cssStr, key) =>
  cssStr + (variants[key.toLowerCase()] || ``);

export const applyModifiers = (variants = {}, styles = '') => {
  if (typeof styles === 'object' && !Array.isArray(styles)) {
    return Object.keys(styles).reduce(_toCSS.bind(null, variants), '');
  }

  if (Array.isArray(styles)) {
    return styles.reduce(_toCSS.bind(null, variants), '');
  }

  return variants[styles.toLowerCase()] || ``;
};

export const makeAnimation = (animations, animator, modifiers) => {
  if (typeof styles === 'object') {
    const mods = Array.isArray(modifiers) ? modifiers : Object.keys(modifiers);
    const animationKeys = Object.keys(animations);
    const animationsToApply = _intersection(animationKeys, mods);
    if (animationsToApply.length) {
      animationsToApply.forEach(animation => {
        animator(animations[animation]);
      });
    }
  } else if (animations[modifiers]) {
    return animator(animations[modifiers]);
  } else {
    return ``;
  }
};

export const betZero = () => {
  console.log('zeros + 1 - 18:1');
  console.log('------------------');
  console.log('this can also work splitting any 2 numbers');
  let bet = 2;
  let total = bet;
  for (let i = 1; i <= 52; i += 1) {
    const win = bet * 18;
    console.log(
      `${i}.`,
      'bet',
      bet,
      'win',
      win,
      'total',
      total,
      'profit',
      win - total
    );
    bet += 1;
    total += bet;
  }
};

export const doubleUp = ({ bet, rounds }) => {
  console.log('double up - 2:1');
  let total = bet;
  let totalWinnings = 0;
  for (let i = 1; i <= rounds; i += 1) {
    const win = bet * 2;
    console.log(
      `${i}.`,
      'bet',
      bet,
      'win',
      win,
      'total',
      total,
      'profit',
      win - total
    );
    bet *= 2;
    total += bet;
    totalWinnings += 3;
  }
  console.log('Total winnings', totalWinnings);
};
