import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {};

const StyledH4 = styled.h4`
  /* base styles can go here */
  font-family: 'Lato', sans-serif;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 0 15px 0;
  border-bottom: solid 1px #e7e7e7;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const H4 = ({ children, ...props }) => (
  <StyledH4 {...props}>{children}</StyledH4>
);

export default H4;
