import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  'work-experience': `
    margin: 100px 0 70px 0;

    @media (min-width: 768px) {

    }
  `,
  'awards-recognition': `
    padding: 70px 0 100px 0;
  `,
  'border-top': `
    border-top: solid 1px #e7e7e7;
  `,
  'padding-top': `
    padding-top: 70px;
  `,
  'padding-bottom': `
    padding-bottom: 70px;
  `,
};

const StyledSection = styled.section`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Section = ({ children, ...props }) => (
  <StyledSection {...props}>{children}</StyledSection>
);

export default Section;
