import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {};

const StyledFooter = styled.footer`
  /* base styles can go here */
  background: #333;
  height: 316px;
  position: relative;
  font-size: 16px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  line-height: 34px;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}

  @media (min-width: 768px) {
    font-size: 22px;
    height: 250px;
  }
`;

const Footer = ({ children, ...props }) => (
  <StyledFooter {...props}>{children}</StyledFooter>
);

export default Footer;
