import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { applyModifiers, makeAnimation } from '../lib/utils';

const animator = animationName => css`
  ${animationName} 2s both;
`;

const modifiers = {
  'logo-left': `
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 200px;
    width: 42px;
    height: 45px;

    @media (min-width: 768px) {
        top: 309px;
        width: 84px;
        height: 90px;
    }
  `,
  'logo-right': `
    position: absolute;
    left: -38px;
    right: 0;
    margin: 0 auto;
    top: 199px;
    width: 42px;
    height: 46px;

    @media (min-width: 768px) {
      top: 308px;
      width: 84px;
      height: 90px;
    }
  `,
  'nav-logo': `
    position: relative;
    float: left;
    top: 0;
    width: 42px;
    height: 45px;
  `,
  'nav-logo-right': `
    opacity: 0;
    left: -18px;
    transition: all 0.5s ease-out;  
  `,
};

const animations = {
  'logo-left': keyframes`
    0% {
      opacity: 0;
      transform: translate3d(0, -70px, 0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
    100% {
      opacity: 1;
      transform: translate3d(-96px, 0, 0);
    }  
  `,
  'logo-right': keyframes`
    0% {
      opacity: 0;
      transform: none;
    }
    50% {
      opacity: 0;
      transform: none;
    }
    100% {
      opacity: 1;
      transform: translate3d(114px, 0, 0);
    }
  `,
};

const mobileAnimations = {
  'logo-left': keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, -270px, 0);
    }
    50% {
        opacity: 1;
        transform: none;
    }
    100% {
        opacity: 1;
        transform: translate3d(-204px, 0, 0);
    }  
  `,
  'logo-right': keyframes`
    0% {
        opacity: 0;
        transform: none;
    }
    50% {
        opacity: 0;
        transform: none;
    }
    100% {
        opacity: 1;
        transform: translate3d(224px, 0, 0);
    }  
  `,
};

export const StyledImg = styled.img`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}

  /* if animation is applied to this element */
  animation: ${props =>
    makeAnimation.bind(null, animations, animator, props.modifiers || props)};

  @media (min-width: 768px) {
    animation: ${props =>
      makeAnimation.bind(
        null,
        mobileAnimations,
        animator,
        props.modifiers || props
      )};
  }
`;

export const imgThemes = {
  default: StyledImg,
  'nav-logo-right': styled.img`
    /* modifiers are applied here */
    ${props => applyModifiers(modifiers, props.modifiers || props)}
  `,
};

const Img = ({ children, type, ...props }) => {
  const ThemedImg = imgThemes[type] || imgThemes.default;
  return <ThemedImg {...props}>{children}</ThemedImg>;
};

export default Img;
