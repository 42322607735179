import React from 'react';
import styled from 'styled-components';
import { applyModifiers, makeAnimation } from '../lib/utils';

import TypeAnimation, { animator } from './type-animation';

const modifiers = {
  intro: `
    opacity: 0;
    position: absolute;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
    display: block;
    top: 212px;
    left: 50%;
    right: 0;
    margin: 0 0 0 -75px;
    font-weight: 500;

    @media (min-width: 768px) {
      opacity: 0;
      font-size: 38px;
      font-weight: bold;
      width: 318px;
      top: 326px;
      margin: 0 0 0 -159px;
    }
  `,
};

const animations = {
  intro: TypeAnimation,
};

const StyledHeading = styled.h1`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}

  /* if animation is applied to this element */
  animation: ${props =>
    makeAnimation.bind(null, animations, animator, props.modifiers || props)};
  animation-delay: 0.75s;
  animation-fill-mode: both;
`;

const H1 = ({ children, ...props }) => (
  <StyledHeading {...props}>{children}</StyledHeading>
);

export default H1;
