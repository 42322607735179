import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  'no-disc': `
    list-style-type: none;
  `,
};

const StyledDd = styled.dd`
  /* base styles can go here */
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin: 0 0 3px 2px;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Dd = ({ children, ...props }) => (
  <StyledDd {...props}>{children}</StyledDd>
);

export default Dd;
