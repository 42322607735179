import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  intro: `
    height: 100vh;
  `,
};

const StyledHeader = styled.header`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Header = ({ children, ...props }) => (
  <StyledHeader {...props}>{children}</StyledHeader>
);

export default Header;
