import React from 'react';
import styled from 'styled-components';

import { imgThemes } from './img';
import { StyledH2 } from './h2';

import TypeAnimation, { animator } from './type-animation';

import { applyModifiers, makeAnimation } from '../lib/utils';

const modifiers = {
  about: `
    padding: 133.5px 0;
    background: #5278a2;

    @media (min-width: 768px) {

    }
  `,
  nav: `
    width: 42px;
    height: 45px;
    margin: 12px 0 0 0;
    overflow: hidden;
    cursor: pointer;
    background: none;

    @media (min-width: 768px) {
      &:hover {
        width: 268px;
      }

      &:hover ${imgThemes['nav-logo-right']} {
        opacity: 1;
        transform: translate3d(176px, 0, 0);
      }

      &:hover ${StyledH2} {
        opacity: 1;
      }
    }  
  `,
  copyright: `
    height: 66px;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: #fab07f;
    bottom: 0;
    right: 0;  

    @media (min-width: 768px) {
      height: 250px;
      z-index: 0;
      width: 34.6%;
      bottom: 0;      
    }
  `,
  'text-center': `
    text-align: center;
  `,
  'text-left': `
    @media (min-width: 768px) {
      text-align: left;
    }
  `,
  flex: `
    display: flex;
  `,
  center: `
    margin: 0 auto;
  `,
};

const animations = {
  nav: TypeAnimation,
};

const StyledDiv = styled.div`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}

  /* if animation is applied to this element */
  @media (min-width: 768px) {
    &:hover ${StyledH2} {
      animation: ${props =>
        makeAnimation.bind(
          null,
          animations,
          animator,
          props.modifiers || props
        )};
    }
  }
`;

const Div = ({ children, ...props }) => (
  <StyledDiv {...props}>{children}</StyledDiv>
);

export default Div;
