import React from 'react';

// components
import { Container, Row, Col } from 'react-bootstrap';
import { Heading } from '../molecules';
import { Section, P, Strong } from '../atoms';

const renderAward = ({ year, description }, index) => (
  <Row data-aos="fade-up" className="row" key={`award-${index}`}>
    <Col
      sm={{ span: 2, offset: 1 }}
      lg={{ span: 1, offset: 1 }}
      className="no-padding-left"
    >
      <P modifiers="margin-top">{year}</P>
    </Col>
    <Col sm={9} lg={10}>
      <Strong modifiers="margin-top">{description}</Strong>
    </Col>
  </Row>
);

const Awards = ({ heading, list }) => (
  <Section
    modifiers={['awards-recognition', 'border-top']}
    id="awards-recognition"
  >
    <Container>
      <Heading icon={heading.icon} title={heading.title} noAnimation />
      {list.map(renderAward)}
    </Container>
  </Section>
);

export default Awards;
