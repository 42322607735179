import React from 'react';
import Image from 'gatsby-image';
import scrollTo from '../lib/scroll-into-view';
import { toggleNavigationVisibility } from '../lib/navbar';

// components
import { Span, H1, Header, Img, Button } from '../atoms';

// assets
import logoLeft from '../images/jk-logo-white.svg';
import logoRight from '../images/bracket.svg';

const Intro = props => {
  const { metaData, title, background, href = '#about' } = props;
  const clickHandler = e => {
    if (href === '#about') {
      e.preventDefault();
      scrollTo('about', 800, true, () => toggleNavigationVisibility(true));
    }
  };
  return (
    <Header modifiers="intro" id="intro">
      <Image
        fluid={background.childImageSharp.fluid}
        alt="sunset"
        style={{
          position: 'absolute',
          zIndex: '-1',
          width: '100%',
          height: '100vh',
        }}
      />
      <Img src={logoLeft} alt={metaData.logoLeft} modifiers="logo-left" />
      <H1 modifiers="intro">
        {title}
        <Span modifiers="blink">|</Span>
      </H1>
      <Img src={logoRight} alt={metaData.logoRight} modifiers="logo-right" />
      <Button href={href} type="go" onClick={clickHandler}>
        Go
      </Button>
    </Header>
  );
};

export default Intro;
