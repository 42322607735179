import React, { PureComponent } from 'react';
import styled from 'styled-components';

/**
 * Button Themes
 */
const buttonThemes = {
  default: styled.button``,
  go: styled.a`
    display: block;
    border: solid 1px #fff;
    position: absolute;
    bottom: 45px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    line-height: 2.9;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    font-family: 'Lato', sans-serif;
    background: none;
    transition: all 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    :hover {
      transform: scale(1.2);
      text-decoration: none;
      color: #ffffff;
    }
  `,
  hyperlink: styled.a`
    color: black;
    text-decoration: none;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  `,
};

class Button extends PureComponent {
  render() {
    const { children, type, ...otherProps } = this.props;
    const ThemedButton = buttonThemes[type] || buttonThemes.default;
    return <ThemedButton {...otherProps}>{children}</ThemedButton>;
  }
}

export default Button;
