import React from 'react';

// components
import { Container, Row, Col } from 'react-bootstrap';
import { Heading, SubHeading } from '../molecules';
import { Section, Ul, Strong, Li } from '../atoms';

const renderSkill = (item, index) => (
  <Li dangerouslySetInnerHTML={{ __html: item }} key={`award-${index}`} />
);

const renderColumns = (
  leftKey,
  rightKey,
  section,
  leftMods = '',
  rightMods = ''
) => (
  <Row data-aos="fade-up">
    <Col sm={7} lg={{ span: 6, offset: 1 }}>
      <Strong modifiers="margin-top">{section[leftKey].title}</Strong>
      <Ul modifiers={leftMods}>{section[leftKey].list.map(renderSkill)}</Ul>
    </Col>
    <Col sm={5} lg={5}>
      <Strong modifiers="margin-top">{section[rightKey].title}</Strong>
      <Ul modifiers={rightMods}>{section[rightKey].list.map(renderSkill)}</Ul>
    </Col>
  </Row>
);

const Skills = ({ heading, section1, section2 }) => (
  <Section
    modifiers={['border-top', 'padding-top', 'padding-bottom']}
    id="skills-techniques"
  >
    <Container>
      <Heading icon={heading.icon} title={heading.title} />

      <SubHeading icon={section1.heading.icon} title={section1.heading.title} />
      {renderColumns('column1', 'column2', section1)}
      {renderColumns(
        'column3',
        'column4',
        section1,
        'two-column',
        'two-column'
      )}
      <SubHeading icon={section2.heading.icon} title={section2.heading.title} />
      {renderColumns('column1', 'column2', section2, 'two-column')}
    </Container>
  </Section>
);

export default Skills;
