import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  logo: `
    position: relative;
    float: left;
    left: -36px;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    width: 176px;
    margin: 12px 0 0 0;
    opacity: 0;
  `,
};

export const StyledH2 = styled.h2`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const H2 = ({ children, ...props }) => (
  <StyledH2 {...props}>{children}</StyledH2>
);

export default H2;
