import { keyframes, css } from 'styled-components';

export default keyframes`
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    opacity: 1;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100;
  }
`;

export const animator = animationName => css`
  ${animationName} 2s steps(30, end);
`;
