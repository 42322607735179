import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {};

const StyledMain = styled.main`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Main = ({ children, ...props }) => (
  <StyledMain {...props}>{children}</StyledMain>
);

export default Main;
