import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  'two-column': `
    @media (min-width: 768px) {
      -webkit-column-count: 2;    
      -moz-column-count: 2;    
      column-count: 2;
    }     
  `,
  menu: `
    list-style: none;
    margin: 22px 0 0 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;  

    @media (min-width: 768px) {
      flex-direction: row;
    }
  `,
};

const StyledUl = styled.ul`
  /* base styles can go here */
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin: 15px 0 0 0;
  padding: 0 0 0 2px;
  line-height: 24px;
  list-style-position: inside;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Ul = ({ children, ...props }) => (
  <StyledUl {...props}>{children}</StyledUl>
);

export default Ul;
