import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { applyModifiers, makeAnimation } from '../lib/utils';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const animator = animationName => css`
  ${animationName} 2s both;
`;

const animations = {
  visible: fadeIn,
};

const modifiers = {
  hidden: `
    display: none;
  `,
  visible: `    
    position: fixed;
    width: 100%;    
    z-index: 1030;
    background: #ffffff;
    border-bottom: solid 1px #e7e7e7;
    height: 70px;    
    display: block;
  `,
  modal: `
    padding-right: 15px;
  `,
};

const StyledNav = styled.nav`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}

  /* if animation is applied to this element */
  animation: ${props =>
    makeAnimation.bind(null, animations, animator, props.modifiers || props)};
`;

const Nav = ({ children, ...props }) => (
  <StyledNav {...props}>{children}</StyledNav>
);

export default Nav;
