import React from 'react';

// components
import { Row, Col } from 'react-bootstrap';
import { H3, I } from '../atoms';

// TOOD: figure out how to apply classnames to bootstrap components
// without having to define utility classes...
// TOOD: may need to improve the API of this file so we can define
// the modifiers that are applied to the icon and the heading
const Heading = ({ icon, title, modifiers, noAnimation }) => {
  const rowProps = {
    ...(!noAnimation && {
      'data-aos': 'fade-up',
    }),
  };
  return (
    <Row {...rowProps}>
      <Col xs={3} sm={1} lg={1} className="no-padding-right">
        <I modifiers={icon} />
      </Col>
      <Col xs={9} sm={11} lg={11} className="no-padding-left">
        <H3 modifiers={modifiers}>{title}</H3>
      </Col>
    </Row>
  );
};

export default Heading;
