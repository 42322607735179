import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {};

const StyledEm = styled.em`
  /* base styles can go here */
  font-style: italic;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Em = ({ children, ...props }) => (
  <StyledEm {...props}>{children}</StyledEm>
);

export default Em;
