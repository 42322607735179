import React, { useContext } from 'react';

// components
import { Container, Row, Col } from 'react-bootstrap';
import { Span, H2, Img, Button, Ul, Li, Div, Nav } from '../atoms';

// lib
import scrollTo from '../lib/scroll-into-view';

// assets
import logoLeft from '../images/jk-logo-black.svg';
import logoRight from '../images/bracket-black.svg';

import { AppContext } from '../templates';

const renderMenuLink = ({ label, href, options = {}, mobile }, index) => {
  const linkModifiers = ['hidden-mobile', 'link'].filter(modifier => {
    if (modifier === 'hidden-mobile') {
      return !mobile;
    }
    return false;
  });
  return (
    <Li modifiers={linkModifiers} key={`menu-${index}`}>
      <Button
        type="hyperlink"
        href={href}
        onClick={e => {
          e.preventDefault();
          scrollTo(href.replace('#', ''), 800, options);
        }}
      >
        {label}
      </Button>
    </Li>
  );
};

const getModifiers = ({ visible, modalShow }) =>
  ['hidden', 'visible', 'modal'].filter(modifier => {
    if (modifier === 'hidden' && visible) {
      return false;
    }

    if (modifier === 'visible' && !visible) {
      return false;
    }

    if (modifier === 'modal' && !modalShow) {
      return false;
    }

    return true;
  });

const Navigation = ({ metaData, title, links, visible }) => {
  const { modalShow } = useContext(AppContext);
  const navModifiers = getModifiers({ visible, modalShow });
  return (
    <>
      <Nav modifiers={navModifiers} id="navbar">
        <Container>
          <Row>
            <Col lg={6} xs={3}>
              <Div modifiers="nav">
                <Button
                  type="hyperlink"
                  href="#intro"
                  onClick={e => {
                    e.preventDefault();
                    scrollTo('intro', 800, true);
                  }}
                >
                  <Img
                    modifiers="nav-logo"
                    src={logoLeft}
                    alt={metaData.logoLeft}
                  />
                  <Img
                    type="nav-logo-right"
                    modifiers={['nav-logo', 'nav-logo-right']}
                    src={logoRight}
                    alt={metaData.logoRight}
                  />
                </Button>
                <H2 modifiers="logo">
                  {title}
                  <Span modifiers="blink">|</Span>
                </H2>
              </Div>
            </Col>
            <Col lg={6} xs={9}>
              <Ul modifiers="menu">{links.map(renderMenuLink)}</Ul>
            </Col>
          </Row>
        </Container>
      </Nav>
    </>
  );
};

export default Navigation;
