import React, { useContext } from 'react';
import Recaptcha from 'react-recaptcha';

// components
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Footer, Div, P, I, Span, A } from '../atoms';

import { AppContext } from '../templates';

const FooterTemplate = () => {
  const { modalShow, setModalShow } = useContext(AppContext);

  const forHumansOnly = () => {
    const spread = 'jam';
    const badBrowser = 'ie';
    const uri = 'gjyllek';
    const commerce = 'com';
    const letter = `${spread + badBrowser}@${uri
      .split('')
      .reverse()
      .join('')}.${commerce}`;
    const humansOnly = document.getElementById('captcha');
    humansOnly.innerHTML = letter;
    setModalShow(false);
  };

  return (
    <Footer id="footer">
      <Div copyright>
        <P modifiers={['text-center', 'mt-15', 'mt-112', 'fs-22']}>
          <I copyright />
          <Span modifiers={['uppercase', 'fs-22']}>Jamie Kelly</Span>
        </P>
      </Div>
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 1 }}>
            <Div id="contact-info">
              <P footer>
                Interested in working together?
                <br />
                Email me at&nbsp;
                <Span
                  modifiers="captcha"
                  id="captcha"
                  onClick={() => setModalShow(true)}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  [click to reveal]
                </Span>
              </P>
              <Div modifiers={['text-center', 'text-left']}>
                <A
                  href="https://github.com/j-quelly"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <I github></I>
                </A>
                <A
                  href="https://ca.linkedin.com/in/jamie-kelly-02652721"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <I linkedin></I>
                </A>
                <A
                  href="https://twitter.com/j_quelly"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <I twitter></I>
                </A>
              </Div>
            </Div>
          </Col>
        </Row>
      </Container>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        verifyCallback={forHumansOnly}
      />
    </Footer>
  );
};

const MyVerticallyCenteredModal = ({ verifyCallback, ...props }) => (
  <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body>
      <Div flex>
        <Div center>
          <Recaptcha
            sitekey="6LehlQ4UAAAAAJ8HqM4-7LyKLg7XkoNSoBoXtVEd"
            verifyCallback={verifyCallback}
          />
        </Div>
      </Div>
    </Modal.Body>
  </Modal>
);

export default FooterTemplate;
