import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  spacer: `
    margin: 45px -15px 0 -15px;
  `,
};

const StyledRow = styled(Row)`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const ExtendedRow = ({ children, ...props }) => (
  <StyledRow {...props}>{children}</StyledRow>
);

export default ExtendedRow;
