export { default as A } from './a';
export { default as Button } from './button';
export { default as Div } from './div';
export { default as Dd } from './dd';
export { default as Dl } from './dl';
export { default as Dt } from './dt';
export { default as Em } from './em';
export { default as Footer } from './footer';
export { default as H1 } from './h1';
export { default as H2 } from './h2';
export { default as H3 } from './h3';
export { default as H4 } from './h4';
export { default as Header } from './header';
export { default as I } from './i';
export { default as Img } from './img';
export { default as Li } from './li';
export { default as Main } from './main';
export { default as Nav } from './nav';
export { default as P } from './p';
export { default as Row } from './row';
export { default as Section } from './section';
export { default as Strong } from './strong';
export { default as Span } from './span';
export { default as Ul } from './ul';
