import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const largeIcon = `
    font-size: 28px;
    border: solid 1px #5278a2;
    border-radius: 100%;
    padding: 10px;
    line-height: 66px;
    color: #5278a2;   
`;

const smallIcon = `
  font-size: inherit;
  padding: 0 10px 0 0;
`;

const mediumIcon = `
  font-size: 22px;
  border: solid 1px #fff;
  border-radius: 100%;
  padding: 10px;
  line-height: 100px;
  margin: 0 20px 0 0;
  transition: all 0.3s ease-in-out;
`;

const modifiers = {
  'work-experience': `
    &::before {
        content: "\\62";
        ${largeIcon}
    }
   `,
  education: `
    &::before {
        content: "\\61";
        ${largeIcon}     
    }
   `,
  skills: `
    &::before {
      content: "\\67";
      ${largeIcon}     
    }
   `,
  development: `
    &::before {
      content: "\\64";
      ${smallIcon}     
    }   
   `,
  other: `
   &::before {
     content: "\\65";
     ${smallIcon}     
   }   
  `,
  awards: `
   &::before {
     content: "\\70";
     ${largeIcon}     
   }   
  `,
  copyright: `
   &::before {
     content: "\\6f";
     ${smallIcon}     
   }   
  `,
  linkedin: `
   &::before {
     content: "\\6d";
     ${mediumIcon}     
   }   
  `,
  twitter: `
    &::before {
      content: "\\6c";
      ${mediumIcon}     
    }   
 `,
  github: `
    &::before {
      content: "\\63";
      ${mediumIcon}     
    }   
  `,
};

const StyledI = styled.i`
  /* base styles can go here */
  font-family: 'jamie' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const I = ({ children, ...props }) => <StyledI {...props}>{children}</StyledI>;

export default I;
