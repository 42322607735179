import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {};

const StyledH3 = styled.h3`
  /* base styles can go here */
  font-size: 18px;
  font-family: 'Noto Serif', serif;
  font-weight: bold;
  color: #5278a2;
  margin-top: 20px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 28px;
  }

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const H3 = ({ children, ...props }) => (
  <StyledH3 {...props}>{children}</StyledH3>
);

export default H3;
