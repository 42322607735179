import React from 'react';

// atoms
import { Container, Row, Col } from 'react-bootstrap';
import { Div, P } from '../atoms';

const About = ({ body }) => (
  <Div about id="about">
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }} xs={12}>
          <P about dangerouslySetInnerHTML={{ __html: body }} />
        </Col>
      </Row>
    </Container>
  </Div>
);

export default About;
