import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  'margin-top': `
    margin: 45px 0 0 0;
    display: block;
  `,
};

const StyledStrong = styled.strong`
  /* base styles can go here */
  color: #333333;
  font-family: 'Lato', sans-serif;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Strong = ({ children, ...props }) => (
  <StyledStrong {...props}>{children}</StyledStrong>
);

export default Strong;
