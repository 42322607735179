import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  link: `
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 18px;
    }  
  `,
  'hidden-mobile': `
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  `,
};

const StyledLi = styled.li`
  /* base styles can go here */

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const Li = ({ children, ...props }) => (
  <StyledLi {...props}>{children}</StyledLi>
);

export default Li;
