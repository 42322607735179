import React from 'react';

// components
import { Col } from 'react-bootstrap';
import { P, Dl, Dt, Strong, Em, Dd, Ul, Li, Row } from '../atoms';

const renderSingleColumn = (course, index) => (
  <Dd key={`course-${index}`}>{course}</Dd>
);

const renderTwoColumn = (course, index) => (
  <Li key={`course-${index}`}>{course}</Li>
);

const School = ({
  startYear,
  endYear,
  schoolName,
  program,
  degree,
  courses,
  twoColumn,
}) => (
  <Row spacer data-aos="fade-up">
    <Col
      sm={{ span: 3, offset: 1 }}
      lg={{ span: 3, offset: 1 }}
      className="no-padding-left"
    >
      <P>
        {startYear} - {endYear}
      </P>
    </Col>
    <Col sm={8} lg={8} xs={12}>
      <Dl>
        <Dt modifiers={!degree && !twoColumn ? 'margin-bottom' : ''}>
          <Strong>{schoolName} | </Strong>
          <Em>{program}</Em>
        </Dt>
        {degree && (
          <Dt modifiers={['margin-bottom', 'normal-font-weight']}>{degree}</Dt>
        )}
        {twoColumn ? (
          <Dd no-disc>
            <Ul modifiers="two-column">{courses.map(renderTwoColumn)}</Ul>
          </Dd>
        ) : (
          courses.map(renderSingleColumn)
        )}
      </Dl>
    </Col>
  </Row>
);

export default School;
