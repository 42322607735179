import React from 'react';

// components
import { Col } from 'react-bootstrap';
import { H4, I, Row } from '../atoms';

const SubHeading = ({ icon, title }) => (
  <Row spacer data-aos="fade-up">
    <Col lg={{ span: 11, offset: 1 }}>
      <H4>
        <I modifiers={icon} /> {title}
      </H4>
    </Col>
  </Row>
);

export default SubHeading;
