import React from 'react';
import styled from 'styled-components';
import { applyModifiers } from '../lib/utils';

const modifiers = {
  about: `
    color: #fff;
    font-family: "Noto Serif", serif;
    line-height: 28px;
    margin: 0;
    font-size: 18px;

    @media (min-width: 768px) {
      line-height: 56px;
      font-size: 28px;
    }
  `,
  'margin-top': `
    margin: 45px 0 0 0;
  `,
  'mt-45': `
    margin: 45px 0 0 0;
  `,
  'mt-15': `
    margin: 15px 0 0 0;
  `,
  'text-center': `
    text-align: center;
  `,
  'mt-112': `
    @media (min-width: 768px) {
      margin-top: 112px;
    }
  `,
  footer: `
    margin: 45px 0 0 0;
    text-align: center;
    font-size: 18px;
    
    @media (min-width: 768px) {
      margin-top: 70px;
      text-align: left;
      font-size: 22px;
    }
  `,
  'fs-22': `
    font-size: 22px;
  `,
};

const StyledP = styled.p`
  /* base styles can go here */
  font-size: 16px;
  font-family: 'Lato', sans-serif;

  /* modifiers are applied here */
  ${props => applyModifiers(modifiers, props.modifiers || props)}
`;

const P = ({ children, ...props }) => <StyledP {...props}>{children}</StyledP>;

export default P;
